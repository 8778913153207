<template>
  <div class="live-script">
    <div class="container">
      <div class="head-box">
        <img class="decorate" src="~assets/image/student/live/scriptLeftDecorate.png" />
        <div class="title">直播带货脚本</div>
        <img class="decorate" src="~assets/image/student/live/scriptRightDecorate.png" />
      </div>
      <div class="content">
        <template v-if="scriptData.length > 0">
          <el-scrollbar style="height: 100%; flex: 1" v-if="liveState == 0">
            <not-start-script :scriptData="scriptData"></not-start-script>
          </el-scrollbar>
          <div v-else class="live-ing-script">
            <span class="text">{{ currentScript.script_content }}</span>
          </div>
        </template>
        <template v-else>
          <div class="empty">无脚本信息</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NotStartScript from "@/components/student/liveRoom/liveScript/NotStartScript";
export default {
  name: "LiveScript",
  data() {
    return {};
  },
  props: {
    scriptData: {
      type: Array,
      default: () => [],
    },
    liveState: {
      type: Number,
      default: 0,
    },
    currentScript: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    packList() {
      return this.scriptData;
    },
  },
  components: {
    NotStartScript,
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.live-script {
  box-sizing: border-box;
  flex: 6;
  font-size: 14px;
  color: #fff;
  background-color: #0c0e3f;
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .head-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100%;
      font-size: 24px;
      .title {
        margin: 0 24px;
        margin-top: -2px;
      }
      .decorate {
        width: 41px;
        height: 15px;
        vertical-align: middle;
      }
    }
    .content {
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .live-ing-script {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 85%;
        height: 100%;
        font-size: 24px;
        line-height: 72px;
        letter-spacing: 1px;
        word-break: break-all;
        overflow: hidden;
        .text {
          transform: translateY(-30px);
        }
      }
      .empty {
        position: absolute;
        top: 40%;
        font-size: 20px;
        color: #aaa;
      }
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
